import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from '@emotion/styled'
import Img from 'gatsby-image'
import { css } from '@emotion/react'
import colours from '../../styles/colours'
import breaks from '../../styles/breakpoints'

// Helpers.
const iconLinks = {
  github: 'https://github.com/larsvers',
  linkedin: 'https://www.linkedin.com/in/larsverspohl/',
  mail: 'mailto:lars@datamake.io',
  phone: 'tel:+447907582011',
  twitter: 'https://twitter.com/lars_vers',
}

const imgMeInfo = {
  meSmile: '50%',
  meHand: '100%',
}

function getImgName(img) {
  const { src } = img.childImageSharp.fluid
  return src.substring(src.lastIndexOf('/') + 1, src.lastIndexOf('.'))
}

// CSS
const Container = styles.div`
  min-height: 100vh;
  padding: 4rem 0 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  @media ${breaks.monitorUp} {
    padding: 0;
  }
`

const containerWrap = css`
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'image text'
    'clients clients'
    'about about';

  @media ${breaks.laptopUp} {
    width: 60%;
  }
`

const imageWrap = css`
  position: relative;
  margin: 1.5rem;
  box-shadow: 4px 4px 20px ${colours.yellow0};
  filter: blur(2px);
  border: 1px solid ${colours.grey2};
  transition: all 2s;

  &:hover {
    box-shadow: 0 0 0 ${colours.yellow1};
    filter: blur(0px);
  }
`

const textWrap = css`
  grid-area: text;
  margin: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-family: 'HN Light';
    letter-spacing: 0.025em;
    line-height: 1.4;
    margin: 0.5rem 0;
  }

  a {
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: ${colours.pink1};
    }

    &:link {
      text-decoration: none;
      padding-bottom: 0.1em;
      transition: all 0.5s;
    }

    &:hover {
      text-shadow: 0px 0px 15px ${colours.pink1};
    }
  }
`

const clientWrap = css`
  grid-area: clients;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media ${breaks.monitorUp} {
    padding: 0 9rem;
  }

  .img-wrap {
    position: relative;
    height: 3rem;
    min-width: 100px;
    padding: 1em;
  }
`

const aboutWrap = css`
  grid-area: about;
  margin: 2rem 0;
  padding-top: 2rem;
  border-top: 1px dotted black;
`

const iconWrapCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const iconCss = css`
  width: 25px;
  height: 25px;
  padding: 0.8em;
  margin: 0.2em;
  transform: rotate(0deg) scale(1);
  transition: transform 0.5s;

  &:focus {
    outline: none;
  }
`

const big = css`
  transform: rotate(360deg) scale(1.2);
`

const copyButton = css`
  display: block;
  border: none;
  padding: 0;
  margin: 0.5rem auto;
  text-decoration: none;
  background: none;
  font-family: 'HN Light';
  font-size: 0.75em;
  cursor: pointer;
  text-align: center;
  transition: transform 0.5s;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }

  &:active {
    transform: scale(0.99);
  }
`

const copyPrompt = css`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%) scale(0.9);
  padding: 0.3rem 1rem;
  opacity: 0;
  font-size: 0.75em;
  border: 1px solid ${colours.blue0};
  border-radius: 3px;
  box-shadow: 0px 0px 0px ${colours.black0a};
  transition: all 1s;
`

const show = css`
  transform: translate(-50%, 130%) scale(1);
  box-shadow: 0px 2px 20px ${colours.black0a};
  opacity: 1;
`

const aboutInfoArea = css`
  opacity: 0;
  position: fixed;
  bottom: -100px;
  right: 0;
  pointer-events: none;
`

function Icon({ icon }) {
  const [hoverIcon, setHoverIcon] = useState(false)

  const ref = iconLinks[getImgName(icon)]
  return (
    <a href={ref} target="_blank" rel="noreferrer">
      <div
        className="icon"
        onMouseEnter={() => setHoverIcon(true)}
        onMouseLeave={() => setHoverIcon(false)}
        css={[iconCss, hoverIcon && big]}
        role="button"
        tabIndex="0"
      >
        <Img
          style={{ width: '100%', height: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
          fluid={icon.childImageSharp.fluid}
        />
      </div>
    </a>
  )
}

function AboutLinks({ imageIcons }) {
  const inputRef = useRef()
  const [hovered, setHovered] = useState(false)
  const [clicked, setclicked] = useState(false)

  function hoverInfo(enter) {
    setHovered(!hovered)
    if (enter) setclicked(false)
  }

  function copyInfo() {
    if (typeof window !== 'undefined' && inputRef?.current) {
      inputRef.current.select()
      document.execCommand('copy')
      setclicked(true)
    }
  }

  return (
    <div id="about" css={aboutWrap}>
      <div id="icon-wrap" css={iconWrapCss}>
        {imageIcons.map((icon, i) => (
          <Icon icon={icon} key={i} />
        ))}
      </div>
      <div>
        <div css={[copyPrompt, hovered && show]}>
          {!clicked ? `Click to copy` : `Yay!`}
        </div>
        <button
          css={copyButton}
          onClick={copyInfo}
          onMouseEnter={() => hoverInfo(true)}
          onMouseLeave={() => hoverInfo(false)}
          type="button"
        >
          Lars Verspohl &#183; Datamake ltd &#183; 11576491 &#183; London &#183;
          Europe &#183; Earth
        </button>
      </div>
      <textarea
        css={aboutInfoArea}
        value={`lars verspohl\ndatamake △\nlars@datamake.io\n+44 7907 582011\n42 greenwood rd\nlondon e8 1ab, uk`}
        ref={inputRef}
        readOnly
      />
    </div>
  )
}

function About({ name, colour, imageMe, imageClients, imageIcons }) {
  // Random me image need different x object-positions.
  const meImg = imageMe[Math.round(Math.random())]
  const meObjPos = imgMeInfo[getImgName(meImg)]

  return (
    <Container id={name} style={{ backgroundColor: colour }}>
      <div id="container" css={containerWrap}>
        <div id="image" css={imageWrap}>
          <Img
            style={{
              width: '100%',
              height: '100%',
            }}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: `${meObjPos} 50%`,
            }}
            fluid={meImg.childImageSharp.fluid}
          />
        </div>
        <div id="text" css={textWrap}>
          <p>
            I'm a visualisation designer and developer making bespoke data
            visuals for friendly people, companies and organisations with tools
            like js, react, d3, vue, r, python, three, figma, pen and some
            paper.
          </p>
          <p>
            I'm particularly happy when telling stories or making tools with
            data. But having spent the better part of the last 20 years in the
            deep end of the data- and development-pond I like and am usually
            involved with every stage of the process from wrangling to
            modelling, designing, drawing and animating data on that blank
            canvas of a browser.
          </p>
          <p>
            10 of those years I was Head of Research for a nordic-international
            broadcaster (*
            <a href=" https://www.mtg.com/" _target="blank" rel="noreferrer">
              MTG
            </a>
            ) before I jumped corporate ship to work with a list of kind and
            bright clients, some of which have logos that look like these:
          </p>
        </div>
        <div id="clients" css={clientWrap}>
          {imageClients.map((image, i) => (
            <div key={i} className="img-wrap">
              <Img
                style={{
                  width: '100%',
                  height: '100%',
                }}
                imgStyle={{
                  objectFit: 'contain',
                }}
                fluid={image.childImageSharp.fluid}
              />
            </div>
          ))}
        </div>
        <AboutLinks imageIcons={imageIcons} />
      </div>
    </Container>
  )
}

Icon.propTypes = {
  icon: PropTypes.object,
}

AboutLinks.propTypes = {
  imageIcons: PropTypes.array,
}

About.propTypes = {
  name: PropTypes.string,
  colour: PropTypes.string,
  imageMe: PropTypes.array,
  imageClients: PropTypes.array,
  imageIcons: PropTypes.array,
}

export default About
